import React from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {Link as LinkRouter} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {ButtonStyled} from './LoginButton'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1,0,3)
    },
    button:{
        color: 'white'
    },
    link:{
        textDecoration: 'none',

    },
    wrapLink: {
        margin: theme.spacing(1)
    }
}))

interface LoginLinksProps{
    t: (text: string) => React.ReactNode
    toggle: (event: React.MouseEvent<HTMLButtonElement>) => void
    login: string
}

const LoginLinks: React.FC<LoginLinksProps> = ({t, toggle, login}) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
           {login ==="signIn"
           ?    <>
                    <Grid item xs className={classes.wrapLink}>
                        <LinkRouter to="/forgotPassword" className={classes.link}>
                            <ButtonStyled variant="outlined">
                                {t("forgotPassword")}
                            </ButtonStyled>
                        </LinkRouter>
                    </Grid>
                    <Grid item className={classes.wrapLink}>
                        <ButtonStyled variant="outlined" onClick={toggle}>
                            {t("dontHaveAccount")}
                        </ButtonStyled>
                    </Grid>
                </>
            :   <Grid item>
                    <Button disableElevation href="#" variant="contained" color="secondary" onClick={toggle} className={classes.button}>
                        {t("alreadyUser")}
                    </Button>
                </Grid>
            }
        </Grid>
    );
}

export default LoginLinks;
