import React, {useContext} from 'react';
import {TemaContext} from '../context/themeContext'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {makeStyles} from '@material-ui/core/styles'
import Typography  from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        alignContent: 'center',
        justify: 'center'
    },
    form:{
        margin: theme.spacing(0,0,1,1)
    },
    title:{
        margin: theme.spacing(0.5,0)
    },
    textSubtitle:{
        color: '#9e9e9e',
        fontSize: '1em',
        fontWeight: 350,
        marginTop: theme.spacing(1)
    },
}))
const ToggleTheme = () => {
    const {toggleTheme} = useContext(TemaContext)
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography className={classes.textSubtitle}>tema</Typography>
            <FormControlLabel className={classes.form} control={<Switch onClick={toggleTheme}/>}/>
        </div>
    );
}

export default ToggleTheme;
