import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {signInWithGoogle} from '../firebase'
import Grid from '@material-ui/core/Grid'
import TitleHero, {SubtitleHero, SubcolumnsHero} from '../components/TitleHero'
import {useSpring, animated} from 'react-spring'
import SignOutLayout from '../layouts/SignOutLayout'
import SignUp from '../components/Login/SignUp'
import SignIn from '../components/Login/SignIn'
import authRouter from '../auth'
import {makeStyles} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from '../components/Footer'

const useStyles = makeStyles(theme =>({
    root:{
        height: '100vh'
    },
    image:{
        height: theme.spacing(52),
        backgroundColor: theme.palette.secondary.main,

        [theme.breakpoints.up("sm")]:{
            height: '880px'
        }
    },
    paper:{
        margin: theme.spacing(8,4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left'
    },
    avatar:{
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit:{
        margin: theme.spacing(3, 0, 2)
    },
    submitGoogle:{
        margin: theme.spacing(1, 0, 10)
    },
    paperTerms: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid #000',
        boxShadow: '12px 12px 2px 1px rgba(0, 0, 255, .2)',
        padding: theme.spacing(2,4,3)
    },
    title:{
        color: 'white',
        fontSize: '5em',
        fontWeight: 400,
        opacity: 1,
        [theme.breakpoints.up("sm")]:{
            marginLeft: theme.spacing(5),
            fontSize: '8em'
        }
    },
    infoText:{
        color: 'white',
        fontSize: '2em',
        fontWeight: 900,
        opacity: 1,
        [theme.breakpoints.up("sm")]:{
            marginLeft: theme.spacing(5),
            fontSize: '2.5em'
        }
    },
    infoParagraph:{
        color: 'white',
        fontSize: '1.5em',
        fontWeight: 900,
        marginLeft: theme.spacing(5),

    },
    text:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        margin: theme.spacing(10 ,0,0,0),
        [theme.breakpoints.up("sm")]:{
            textAlign: 'left',
            margin: theme.spacing(6,4,0,2),
        },
        [theme.breakpoints.up("md")]:{
            textAlign: 'left',
            margin: theme.spacing(10,4,0,7),
        },
        [theme.breakpoints.up("lg")]:{
            margin: theme.spacing(15,4,0,7)
        }
    },
    buttons:{
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0,0,2),
        [theme.breakpoints.up("sm")]:{
            margin: theme.spacing(3,0,0,5),
            justifyContent: 'start'
        }
    }
  
}))

const Hero = () =>{
    const classes = useStyles()
    return(
        <Grid item xs={12} sm={4} md={7} className={classes.image}>
            <div className={classes.text}>
                <TitleHero />
                <SubtitleHero />
                <SubcolumnsHero />
            </div>
        </Grid>

    )
}

const Login =() =>{
    const [showSignUp, setShow] = useState<boolean>(true)
    const history = useHistory()
    const toggleShow = () => setShow(!showSignUp)
    const {t} = useTranslation()
    const classes = useStyles()
    const fade = useSpring({from:{opacity: 0}, opacity: 1})
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('xs'))

    const loginAndSendGoogle = async (): Promise<void>  =>{
        await signInWithGoogle()
        authRouter.login()
        history.push('/feed')
    }
    return(
        <SignOutLayout>
                
            <animated.div style={fade}>   
                <Grid container component="main" className={classes.root}>
                    <Hero />
                    {showSignUp 
                    ?   <SignUp
                        classes={classes}
                        t={t}
                        loginAndSendGoogle={loginAndSendGoogle}
                        toggle={toggleShow}
                        authRouter={authRouter}
                        history={history}
                        />
                    :  <SignIn 
                        classes={classes}
                        t={t}
                        loginAndSendGoogle={loginAndSendGoogle}
                        history={history}
                        authRouter={authRouter}
                        toggle={toggleShow}
                    />
                    }
                    {matchesSm && <Footer/>
                }
                </Grid>
                
            </animated.div>

        </SignOutLayout>
        
    )
}

export default Login