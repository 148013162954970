import React, {useState} from 'react'
import {FormControl,Select,MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        flexDirection: 'row',
        color: 'white'
    },
    icon:{
        marginRight: theme.spacing(1)
    },
    selects:{
        color: 'white'
    }
}))

const LanguageSelector = () =>{
    const {i18n } = useTranslation()
    const [language, setLanguage] = useState("es")
    const [languages] = useState([
        {lang: 'es', title: 'ESP'},
        {lang: 'en', title: 'ENG'},
        {lang: 'maya', title: 'MAYA'},
    ])
    const classes = useStyles()

    const changeLanguage = event =>{
        i18n.changeLanguage(event.target.value)
        setLanguage(event.target.value)
    }


    return(
        <FormControl className={classes.root}>
            <Select
                labelId="language-selector"
                id="language-selector"
                value={language}
                onChange={changeLanguage}
                className={classes.selects}
                label="english">
                
                {languages.map(language =>
                    <MenuItem id={language.lang} value={language.lang} key={language.lang}>
                        {language.title}
                    </MenuItem>    
                )}
            </Select>
        </FormControl>
    )
}

export default LanguageSelector