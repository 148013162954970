import React from 'react';
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>({
    root:{
        minWidth: theme.spacing(20)
    }
}))

interface DescriptionProps{
    t: (desc: string) => React.ReactNode
    description?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    size: string
}
const Description: React.FC<DescriptionProps> = ({t,description, onChange, size}) => {
    const classes = useStyles()
    return(
            <TextField 
                className={classes.root}
                label={t("description")}
                name="description"
                multiline
                rows={size}
                value={description}
                onChange={onChange}/>
    )
}

export default Description;
