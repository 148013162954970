import React from 'react';
import Typography from '@material-ui/core/Typography'
import {Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ButtonStyled} from './Login/LoginButton'

const useStyles = makeStyles(theme=>({
    root:{
        color: 'white',

        fontWeight: 900,
        fontSize: '2.5em',
        [theme.breakpoints.up("sm")]:{
            margin: theme.spacing(3,3,0,3),
            textAlign: 'left',
            fontSize: '2.2em',
            padding:theme.spacing(0)
        },
        [theme.breakpoints.up("md")]:{
            fontSize: '3em'
        }
    },
    subtitle:{
        color: 'white',

        fontWeight: 300,
        fontSize: '1.5em',
        margin: theme.spacing(1,1,0,3),
        [theme.breakpoints.up("sm")]:{
            margin: theme.spacing(3,3,0,3),
            textAlign: 'justify',
            padding:theme.spacing(0)
        }
    },
    subcolumns:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        margin: theme.spacing(1),
        [theme.breakpoints.up("sm")]:{
            margin: theme.spacing(3,0)
        },
        [theme.breakpoints.up("lg")]:{
            margin: theme.spacing(5,0),
            justifyContent: 'center',
        },
        [theme.breakpoints.up("xl")]:{
            margin: theme.spacing(8, 0)
        }
        // [theme.breakpoints.up("md")]:{
        //    justifyContent: 'end',
        //    alignItems: 'end',
        //    alignContent: 'left',
        //    margin: theme.spacing(5,0)
        // },
        
    },
    image:{   
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: theme.spacing(0,3,1,5),
        [theme.breakpoints.up("sm")]:{
            width: theme.spacing(25),
            height: theme.spacing(20),
        },
        [theme.breakpoints.up("md")]:{
            width: theme.spacing(35),
            height: theme.spacing(31),
            margin: theme.spacing(0,0,0,12)
        },
        [theme.breakpoints.up("lg")]:{
            margin: theme.spacing(0,3,0,0)
        }
    },
    textSubcolumn:{
        color: 'white',
        textAlign: 'left',
        fontSize: '1em',
        fontWeight: 300,
        justifySelf: 'left',
        margin: theme.spacing(0,10,2,5),
        width: theme.spacing(30),
        [theme.breakpoints.up("md")]:{
            margin: theme.spacing(5,0,0,4)
        },
        [theme.breakpoints.up("lg")]:{
            margin: theme.spacing(4,2,0,9),
            fontSize: '1.2em'
            
        }
        // [theme.breakpoints.up("md")]:{
        //     margin: theme.spacing(4,2,1,0),
        //     width: theme.spacing(50),
        // }
        
    }
}))

export const SubtitleHero = () =>{
    const classes = useStyles()
    return(
        <Typography className={classes.subtitle}>
            <strong>Tulis</strong> te ayuda a <strong>intercambiar</strong> productos <br/>y servicios con otras personas de forma gratuita
        </Typography>
    )
}

export const ButtonLink = () =>
    <Link to="/info" style={{textDecoration: 'none'}}>
        <ButtonStyled variant="contained" color="primary">más información</ButtonStyled>
    </Link>

export const SubcolumnsHero = () =>{
    const classes = useStyles()
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
    return(
        <Grid className={classes.subcolumns}>
            {matchesMd &&
            <>
                <Grid item md={10} lg={4} >

                <img src="https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/recursos%2FilustracionPrincipal.png?alt=media&token=ff0e9f74-a8dd-471b-8356-5aeb1a1d116a"
                    alt="main ilustration" 
                    className={classes.image}/>
                </Grid>
            
                <Grid item>

                <Typography className={classes.textSubcolumn}>
                <strong>
                    De todos para todos <br/>
                    Tulis {' '}
                </strong>
                    significa {' '}
                <strong>     
                    todos en <br/> maya, {' '}
                </strong>
                    porque todos <br/> podemos ayudarnos.<br/>
                    1ra plataforma en el <br/> mundo
                    de intercambio <br/>en maya y español.
                <ButtonLink />
                </Typography>
                </Grid>
            </>
            }
            {matchesSm &&
                <ButtonLink />
             }
        </Grid>
    )
}

const TitleHero = () => {
    const classes = useStyles()
    return (
        <Typography className={classes.root}>Comunidad de <br/> intercambio solidario</Typography>
    );
}

export default TitleHero;
