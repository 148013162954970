import React from 'react';
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        margin: theme.spacing(1)
    },
    title:{
        color: theme.palette.primary.main,
        fontWeight: 900,
        fontSize: '2em'
    },
}))
const HeaderSignIn = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                {children}
            </Typography>
        </div>
    );
}

export default HeaderSignIn;
