import React from 'react'
import {Typography,TextField,Radio,Link, Grid} from '@material-ui/core'
import LoginButtons from './LoginButton'
import AlertMessage from './AlertMessage'
import {SpinnerLogin} from '../Spinner'

import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme =>({
    textCondition:{
        fontSize: '.9em'
    },
    textTerms:{
        fontSize: '1em'
    },
    textRead:{
        fontSize: '1em',
        fontWeight: 900
    },
  
}))

interface SignUpFormProps{
    t: (text: string) => string
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    displayName: string
    handleInputs: (event: React.ChangeEvent<HTMLInputElement>) => void
    email: string
    password: string
    confirmation: string
    toggleTerms: any
    terms: boolean
    toggleTermsModal: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
    loginAndSendGoogle: () => void
    alert: boolean
    showSpinner: boolean

}


const SignUpForm: React.FC<SignUpFormProps> = ({ t, handleSubmit, displayName, handleInputs, email,
    password, confirmation, toggleTerms, terms, toggleTermsModal,
    loginAndSendGoogle, alert, showSpinner})=>{
        const classes = useStyles()
        return(
    <form  onSubmit={handleSubmit}>
        <AlertMessage alert={alert} severity="error">
            Email already in use
        </AlertMessage>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id='displayName'
            label={t('name')}
            name="displayName"
            autoComplete={t("name")}
            autoFocus
            value={displayName}
            onChange={handleInputs}
        />
        <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            required
            id='email'
            label={t('email')}
            name="email"
            autoComplete={t("email")}
            value={email}
            onChange={handleInputs}
        />

        <TextField
            required
            fullWidth
            variant="outlined"
            margin="normal"
            type="password"
            id="password"
            name="password"
            label={t('password')}
            autoComplete={t('password')}
            value={password}
            onChange={handleInputs}
        />
        {password.length < 6 && <Typography className={classes.textCondition} color="textSecondary">{t('passwordMust')}</Typography>}
        <TextField
            variant="outlined"
            fullWidth 
            required
            error={password !== confirmation}
            margin="normal"
            type="password"
            id="confirmation"
            name="confirmation"
            label={t('confirm')}
            autoComplete={t('confirm')}
            value={confirmation}
            onChange={handleInputs}
        />
        <Grid style={{display: 'flex'}} onClick={toggleTerms}>
            <Radio 
                onChange={toggleTerms}
                value={terms}
                checked={terms}
            />
            <Typography 
                style={{marginTop: 10}}
                className={classes.textTerms} color="textSecondary">
                {t("terms")} 
            </Typography>
            <Link 
                onClick={toggleTermsModal}
                style={{marginTop: 11, marginLeft: 10}}
                className={classes.textRead}
                >
                {t("readTerms")}
            </Link>
        </Grid>
        <SpinnerLogin show={showSpinner} />
        <LoginButtons t={t} clickGoogle={loginAndSendGoogle} login="signUp" disabled={!terms}/>
    </form>
    )
}

export default SignUpForm