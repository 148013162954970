import React, {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next'
import {ReportedUserContext} from '../context/reportedUserContext'
import {UserSessionContext} from '../context/userSessionContext'
import {reportUser} from '../utils'
import Paper from '@material-ui/core/Paper'
import MainLayout from '../layouts/MainLayout'
import { Grid, Button } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Description from '../components/Profiles/Description'
import AlertMessage from '../components/Login/AlertMessage'
import SelectComp from '../components/SelectComp'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(10,2,2,2),

    },
    paper: {
        backgroundColor: theme.palette.background.default,
        width: theme.spacing(50),
        textAlign: 'center',
        boxShadow: `12px 12px 2px 1px ${theme.palette.primary.main}`,
        padding: theme.spacing(2,4,3),
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(10)
    },
    title:{
        color: theme.palette.primary.main,
        fontSize: '2em',
        fontWeight: 800
    },
    motivo:{
        display: 'flex',
        alignItems: 'end',
        margin: theme.spacing(1)
    },
    textMotive:{
        margin: theme.spacing(0,2,1)
    },
    formControl:{
        width: theme.spacing(20),
        margin: theme.spacing(1),
        minWidth: theme.spacing(20)
    }
}))

const Report = () => {
    const classes = useStyles()
    const [text, setText] = useState("")
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value)
    const [alertMessage, setAlert] = useState(false)
    const [selectValue, setSelect] = useState("fakeUser")
    const [menuItems]= useState([
        {value: 'acoso', text: 'Acoso'},
        {value: 'fakeProfile', text: 'Perfil Falso'},
        {value: 'spam', text: 'Spam'},
        {value: 'drugs', text: 'Sustancias ilicitas'},
    ])
    function changeSelect(
        event: React.ChangeEvent<{ name?: string | undefined; value: any }>){
        setSelect(event.target.value)
    }

    function toggleAlert(){
        setAlert(true)
        setTimeout(() =>{
            setAlert(false)
        }, 2500)
    }

    const {reportedUser} = useContext(ReportedUserContext)
    const {user} = useContext(UserSessionContext)
    const handleSubmit = async () =>{
        await reportUser(user.uid, reportedUser, text, selectValue)
        toggleAlert()
    }
    const {t} = useTranslation()
    return (
        <MainLayout>
           <Grid container className={classes.root}>
                <Grid item component={Paper} elevation={6} variant="outlined" className={classes.paper} onSubmit={handleSubmit}>
                    <Typography className={classes.title}>Reportar</Typography>
                    <SelectComp value={selectValue} onChange={changeSelect}
                        menuItems={menuItems}/>
                    <Description t={t} onChange={handleTextChange} size="12"/>
                    <AlertMessage severity="success" alert={alertMessage}>Enviado</AlertMessage>
                    <Button onClick={handleSubmit}>{t("submit")}</Button>
                </Grid>
           </Grid>
        </MainLayout>
    );
}

export default Report;
