import React from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme =>({
    motivo:{
        display: 'flex',
        alignItems: 'end',
        margin: theme.spacing(1)
    },
    textMotive:{
        margin: theme.spacing(0,2,1)
    },
    formControl:{
        width: theme.spacing(20),
        margin: theme.spacing(1),
        minWidth: theme.spacing(20)
    }
}))

interface MenuValue{
    value: string
    text: string
}

interface SelectCompProps {
    value: string
    onChange: (
        event: React.ChangeEvent<{ name?: string | undefined; value: any }>, 
        child: React.ReactNode) => void
    menuItems: MenuValue[]
}

const SelectComp: React.FC<SelectCompProps> = ({value, onChange, menuItems}) =>{
    const classes = useStyles()
    return(
        <Grid className={classes.motivo}>
            <Typography color="textSecondary" className={classes.textMotive}>Motivo:</Typography>
            <FormControl className={classes.formControl}>
                <Select
                    value={value}
                    onChange={onChange}>
                        {menuItems.map(menuItem =>
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.text}
                            </MenuItem>
                        )}
                </Select>
            </FormControl>
        </Grid>
    )
}

export default SelectComp