import firebase, {firestore} from './firebase'

export async function sendSuggestion(id, text){
    const suggestionRef = firestore.doc(`suggestions/data`)
    await suggestionRef.update({
        suggestions: firebase.firestore.FieldValue.arrayUnion({
            createdAt: new Date(),
            user: id,
            text,
            read: false,
            readBy: ""
        })
    })


}

export async function reportUser(uid, reportedUser, text, motive){
    const reportedRef = firestore.doc(`reported/${reportedUser.uid}`)
    reportedRef.get()
        .then(docSnapshot =>{
            if (docSnapshot.exists){
               reportedRef.update({
                    reports: firebase.firestore.FieldValue.arrayUnion({
                        createdAt: new Date(),
                        by: uid,
                        text,
                        motive,
                        read: false,
                        readBy: ""
                        
                    })  
                })
            } else {
                reportedRef.set({
                    reports: firebase.firestore.FieldValue.arrayUnion({
                        createdAt: new Date(),
                        by: uid,
                        text,
                        motive,
                        read: false,
                        readBy: ""
                    })  
                })
            }
        })
    
}

export function deconstructSnapshot(snapshot){
    const data = snapshot.docs.map(item => {return {...item.data()}})
    return data
}