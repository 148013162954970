import React from 'react';
import Dialog from '@material-ui/core/Dialog'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    modal:{
        display: 'block',
        alignItems: 'center',
        position: 'absolute',
        justifyContent: 'center',
        overflow: 'scroll'
    },
})

interface DialogConditionsProps{
    open: boolean
    children?: React.ReactNode
}

const DialogContidions: React.FC<DialogConditionsProps> = ({open, children}) => {
    const classes = useStyles()
    return (
        <Dialog
            aria-labelledby="spring-dialog-title"
            aria-describedby="spring-dialog-description"
            className={classes.modal}
            open={open}
            scroll="paper"
            closeAfterTransition
        >
            {children}
        </Dialog> 
    );
}

export default DialogContidions;
