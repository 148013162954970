import React from 'react';
import Typography from '@material-ui/core/Typography'

interface TitleProps{
    children?: React.ReactNode
    component: any
    variant: any 
    color: any
    classes: any
}

const Title: React.FC<TitleProps> = ({children, component, variant="h6", color, classes=null}) => 
    classes ?
        <Typography className={classes.title} component={component} variant={variant} color={color}>
            {children}
        </Typography>
    :   <Typography component={component} variant={variant} color={color}>
            {children}
        </Typography>
   

export default Title;
