import React, {createContext, useReducer} from 'react'
import {feedReducers} from '../reducers/feedReducers'
export const UserContext = createContext()

const initialData = {
  data: [],
  displayList: [],
  isLoading: false,
  isError: false,
  user: {},
  userProfile: {},
  lastUser: {}
}


  
const UserProvider = ({children}) =>{
    const [list, dispatch] = useReducer(feedReducers, initialData)
    return(
        <UserContext.Provider value={{list, dispatch}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider