import React from 'react';
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert';

interface AlerMessageProps{
    alert: boolean
    severity: "warning" | "error" | "info" | "success" | undefined
    children: React.ReactNode
}

const AlertMessage: React.FC<AlerMessageProps> = ({alert, severity, children}) => 
    <Collapse in={alert}>
        <Alert severity={severity}>
            {children}                 
        </Alert>
    </Collapse>
 
export default AlertMessage;
