import React, {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next'
import {UserSessionContext} from '../context/userSessionContext'
import {sendSuggestion} from '../utils'
import Paper from '@material-ui/core/Paper'
import MainLayout from '../layouts/MainLayout'
import { Grid, Button } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Description from '../components/Profiles/Description'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(10,2,2,2),

    },
    paper: {
        backgroundColor: theme.palette.background.default,
        width: theme.spacing(50),
        textAlign: 'center',
        boxShadow: `12px 12px 2px 1px ${theme.palette.primary.main}`,
        padding: theme.spacing(2,4,3),
        display: 'flex',
        flexDirection: 'column',
    },
    title:{
        color: theme.palette.primary.main,
        fontSize: '2em',
        fontWeight: 800
    }
}))

const Suggestions = () => {
    const classes = useStyles()
    const [text, setText] = useState("")
    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)
    const {user} = useContext(UserSessionContext)
    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) =>{
        event.preventDefault()
        sendSuggestion(user.uid, text)
        console.log(text)
    }
    const {t} = useTranslation()
    return (
        <MainLayout>
           <Grid container className={classes.root}>
                <Grid item component={Paper} elevation={6} variant="outlined" className={classes.paper}>
                    <Typography className={classes.title}>Sugerencias</Typography>
                    <Description t={t} onChange={handleTextChange} size="12"/>
                    <Button onClick={handleSubmit}>{t("submit")}</Button>
                </Grid>
           </Grid>
        </MainLayout>
    );
}

export default Suggestions;
