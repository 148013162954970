import React, {useEffect, useCallback, useState, useContext } from 'react'
import MainLayout from '../layouts/MainLayout'
import { UserContext } from '../context/usersContext'
import { UserSessionContext } from '../context/userSessionContext'
import { TemaContext } from '../context/themeContext'
import { useTranslation } from 'react-i18next'
import { useTransition, animated } from 'react-spring'
import UserCard from '../components/UserCard'
import UserProfile from '../components/Profiles/UserProfile'
import FilterBar from '../components/FilterBar'
import {firestore, auth} from '../firebase'
import { makeStyles } from '@material-ui/core/styles'
import {Container} from '@material-ui/core'
import Spinner from '../components/Spinner'
import Error from '../components/Error'
import DrawerProfiles from '../components/DrawerProfiles'
import DialogConditions from '../components/Login/DialogContidions' 
import GuidePopUp from '../components/GuidePopUp'
import LoadUsers from '../components/LoadUsers'

const AnimatedUserCard = animated(UserCard)

const useStyles = makeStyles(theme => ({
  root:{
    marginTop:40,
    backgroundColor: theme.palette.background.default,
  },
  feed:{
    display: 'flex',
    flexWrap: 'wrap'
  }
 
}))
  const Feed = () =>{
    const { list, dispatch} = useContext(UserContext)
    const {theme} = useContext(TemaContext)
    const [ profile, setProfile ] = useState(false)
    const toggleProfile = () => setProfile(!profile)
    const {t} = useTranslation()
    const classes = useStyles(theme)
    const [noType, setNotype] = useState(true)
    const toggleNotype = () => setNotype(!noType)
    const {user} = useContext(UserSessionContext)
   

    const transition = useTransition(list.displayList, item => item.uid, {
      from: { opacity: 0},
      enter: { opacity: 1},
      leave: { opacity: 0}
    })
   
    const fetchData = useCallback(async () =>{
      dispatch({ type: 'SET_LIST_INIT'})
      try { 
        
        const snapshot = await firestore.collection('users').orderBy('lastSession', 'desc').limit(10).get()
        console.log('before', snapshot)
        const users = snapshot.docs.map(user => {return {uid: user.uid, ...user.data()}})
        dispatch({
          type: 'SET_LIST_SUCCESS',
          payload: {
            users: users.filter(user => user.uid !== auth.currentUser.uid),
            lastUser: users[users.length -1]
          }
        })
      } catch (error) {
        dispatch({ type: 'SET_LIST_FAILURE'})
      }
    },[dispatch])
 
    useEffect(() =>{
     fetchData()
    }, [fetchData])

    const loadMoreUsers = async () =>{
      const snapshot = await firestore.collection('users').orderBy('lastSession', 'desc').limit(10).startAfter(list.lastUser.createdAt).get()
      const users =  snapshot.docs.map(user => {return {uid: user.uid, ...user.data()}})
      dispatch({
        type: 'ADD_MORE_USERS',
        payload: users
      })
    }

    return(
      <MainLayout>
        <Container className={classes.root}>
          <br />
          <FilterBar t={t}/>
          {list.isError && <Error />}
          
          {list.isLoading 
            ? <Spinner/>
            : 
            <div className={classes.feed}>
           { transition.map(({item, key, props}) => (
              <AnimatedUserCard 
                key={key} 
                style={props}
                data={item} 
                dispatch={dispatch}
                setProfile={toggleProfile}
                theme={theme}
                t={t} 
              />
             )

            )}
            </div>

          }
          <LoadUsers onClick={loadMoreUsers}/>
          <br />
          <br />
          <br />
          <br />
          <DrawerProfiles anchor="right" open={profile} onClose={toggleProfile}>
            <UserProfile userProfile={list.user} />
          </DrawerProfiles>
          {user ?
            user.type 
              ? null
              : <DialogConditions open={noType}>
                    <GuidePopUp onClose={toggleNotype} t={t}/>
                </DialogConditions>
            : null
          }
        </Container>
      </MainLayout>
    )
  }

export default Feed