import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    button:{
        color: 'white',
        margin: theme.spacing(1)
    },
    buttonLogin:{
        color: 'white',
        marginBottom: theme.spacing(2)
    },
    buttons:{
        display : 'flex',
        justifyContent: 'space-around',
        margin: theme.spacing(1)
    },
    buttonMail:{
        color: 'white',
        width: theme.spacing(18),
        margin: theme.spacing(1),
        [theme.breakpoints.up("md")]:{
            width: theme.spacing(20),

        }
    },
    buttonGoogle:{
        width: theme.spacing(18),
        margin: theme.spacing(1),
        [theme.breakpoints.up("md")]:{
            width: theme.spacing(20),

        }
    }

}))

interface ButtonStyledProps{
    children?: React.ReactNode
    variant?: any
    size?: any
    color?: any
    disabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ButtonStyled: React.FC<ButtonStyledProps> = ({children, onClick, variant="contained", size="small", color="secondary" }) =>{
    const classes = useStyles()
    return( variant === "contained"
        ?   <Button disableElevation onClick={onClick} variant={variant} size={size} color={color} className={classes.button}>
                {children}
            </Button>

        :   <Button onClick={onClick} variant={variant} size={size} color={color}>
                {children}
            </Button>

    ) 
}

export const ButtonSubmitStyled: React.FC<ButtonStyledProps> = ({children, variant="contained", size="small", disabled}) =>{
    const classes = useStyles()
    return( variant === "contained"
        ?   <Button disableElevation disabled={disabled} type="submit" variant={variant} size={size} color="secondary" className={classes.button}>
                {children}
            </Button>

        :   <Button disabled={disabled} type="submit" variant={variant} size={size} color="secondary">
                {children}
            </Button>

    ) 
}

interface LoginButtonProps {
    t: (text: string) => React.ReactNode
    clickGoogle: () => void
    login: string
    disabled?: boolean
}

const LoginButtons: React.FC<LoginButtonProps> = ({t, clickGoogle, login, disabled}) =>{
    const classes = useStyles()
    return(
        <Grid container component="main" className={classes.buttons}>
            <Grid item>
                <Button 
                    disableElevation
                    disabled={disabled}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.buttonMail}
                    >{t(login)}</Button>
            </Grid>
            <Grid item>
                <Button 
                    disableElevation
                    disabled={disabled}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className={classes.buttonGoogle}
                    onClick={clickGoogle}>Google</Button>
            </Grid>
        </Grid>
    )
}

export default LoginButtons

