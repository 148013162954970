import React, {useState} from 'react'
import {auth} from '../../firebase'
import {Grid,Paper} from '@material-ui/core'
import Copyright from './Copyright'

import SignInForm from './SignInForm'
import LoginLinks from './LoginLinks'
import HeaderSignIn from '../HeaderSignIn'

const initialState = { email:"", password: ""}

interface SignInProps{
    t: (text: string) => string
    classes: any
    loginAndSendGoogle: () => void
    toggle: (event: React.MouseEvent<HTMLButtonElement>) => void
    authRouter: any
    history: any
}


const SignIn: React.FC<SignInProps> = ({t,classes, loginAndSendGoogle, toggle, authRouter, history}) =>{
    const [signIn, setSignIn] = useState(initialState)
    const {email, password} = signIn
    const [showSpinner, setSpinner] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false)
    const [tooManyReqs, setTooManyReqs] = useState(false)

   

    function turnCircularProgress(option?:null | string){
        if (option === "on"){
            setSpinner(true)
        } else if(option==="off") {
            setSpinner(false)
            setWrongCredentials(false)
        } else if (option === "tooMany"){
            setSpinner(false)
            setTooManyReqs(true)
        } else {
            setSpinner(false)
            setWrongCredentials(true)

        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSignIn({...signIn, [event.target.name] : event.target.value})

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> =>{
        event.preventDefault()
        turnCircularProgress('off')
        try {
            turnCircularProgress('on')
            const {user} = await auth.signInWithEmailAndPassword(email, password)
            if (user){
                authRouter.login(history.push('/feed'))
            }
        } catch (error) {
            console.error('Error in sign in', error)
            if (error.code === "auth/user-not-found"){
                turnCircularProgress()
            }
            else if (error.code === "auth/wrong-password"){
                turnCircularProgress()
            } else if (error.code === "auth/too-many-requests"){
                turnCircularProgress('tooMany')
            }

        }
    }
    return(
        <Grid item xs={12} sm={8} md={5} component={Paper} square>
            <div className={classes.paper}>
                <HeaderSignIn>{t("signIn")}</HeaderSignIn>
                <SignInForm t={t} classes={classes} handleSubmit={handleSubmit} 
                    email={email} handleInputChange={handleInputChange} 
                    password={password} loginAndSendGoogle={loginAndSendGoogle} 
                    showSpinner={showSpinner} wrongCredentials={wrongCredentials}
                    tooManyReqs={tooManyReqs}/>
                <LoginLinks t={t} toggle={toggle} login="signIn"/>
                <Copyright />        
            </div>
        </Grid>
        )
    }
export default SignIn    