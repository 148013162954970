import React,{useState} from 'react';
import MainLayout from '../layouts/MainLayout'
import { Grid } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ProfileDescription, {Creator} from '../components/ProfileDescription'


const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(10,2,10,1)

    },
    paper: {
        backgroundColor: theme.palette.background.default,
        width: '80vh',
        textAlign: 'center',
        margin: theme.spacing(0,3,0,1),
        boxShadow: `12px 12px 2px 1px ${theme.palette.secondary.main}`,
        padding: theme.spacing(2,4,3),
        display: 'flex',
        flexDirection: 'column'
    },
    title:{
        color: theme.palette.primary.main,
        fontSize: '2em',
        fontWeight: 800
    },
    textContact:{
        fontSize: '1.2em',
        marginTop: theme.spacing(.6)
    },
    contact:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    email: {
        margin: theme.spacing(1,0,0,2)
    }
}))

const Description = () => 
    <p>Intercambia productos y servicios con otros usuarios de forma gratuita</p>

const Contact = ()=>{
    const classes = useStyles()
    return(
    <div className={classes.contact}>
        <Typography className={classes.textContact}>Contacto</Typography>
        <p className={classes.email}>info.tulis.app@gmail.com</p>
    </div>)
}

interface CreatorsProps {
    children?: React.ReactNode
    creators: Creator[]
}

const Creators: React.FC<CreatorsProps> = ({creators})=>
    <>
        {creators.map((creator) =>
            <ProfileDescription key={creator.id} creator={creator} />
        )}
    </>
const Info = () => {
    const classes = useStyles()
    const [creators] = useState<Creator[]>([
        {name: 'Sophie Defauw',
        id:1, 
        image: 'https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/creatorsImages%2FIMG_2536.jpeg?alt=media&token=cb284d95-58f4-46f8-8f38-f61984a2d5f9',
        description: 'Diseñadora, artista y escritora, en el proceso de convertirse en historiadora...Open minded políglota, amante de la arqueología, BigBang theory, la botánica y del café colombiano con azúcar.Lista para viajar y descubrir nuevos lugares 24/7, siempre acompañada de un buen libro de Oscar Wilde. '},
        {name: 'Jesus Cova',
        id:2,
        image: 'https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/creatorsImages%2FIMG_6191.jpg?alt=media&token=c0486215-7cf1-4d14-bb93-12d0e0b8a68e',
        description: 'Desarrollador web, trabajando en aprender Graphql y mejorar mi React.js, Tengo un gusto que llega kitsch por la cultura japonesa y el minimalismo. Full metal Alchemist y me gusta el café que toma Sophie.'
        },
        {name: 'Felipe Cordova',
        id:3,
        image: 'https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/creatorsImages%2FWhatsApp%20Image%202020-05-08%20at%2014.52.46.jpeg?alt=media&token=91a605dd-a0aa-4a10-8c94-427921ee55b4',
        description: 'Diseñador y animador de distintas marcas nacionales con base en Playa del Carmen. Ocupado viendo Love, Death & Robots .'   
    }
    ])
    return (
        <MainLayout>
           <Grid component="main"  container className={classes.root}>
                <Paper className={classes.paper}elevation={6} variant="outlined" >
                    <Typography className={classes.title}>Info</Typography>
                    <Description />
                    <Typography variant="h6">Equipo</Typography>
                    <Creators creators={creators}/>
                    <Contact />
                </Paper>
           </Grid>
        </MainLayout>
    );
}

export default Info;
