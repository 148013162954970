import React, {useContext} from 'react';
import {TemaContext} from '../context/themeContext'
import { makeStyles } from '@material-ui/core/styles';
import {Link, useLocation} from 'react-router-dom'
import '../i18n'
import Title from './Login/Title'
import AppBar from  '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'


import LanguageSelector from '../LanguageSelector'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleItem: {
    marginLeft: theme.spacing(0.7),
    flexGrow: 1,
    display: 'flex',
    color: 'white',
    fontSize: '.8em',
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  title: {
    marginRight: theme.spacing(2),
    color: 'white'
  },
  logo:{
    height: theme.spacing(3)
  }
}));

const Logo = () =>{
  const classes= useStyles()
  return(
    <img className={classes.logo}
      src="https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/logo%2FlogoTulis.png?alt=media&token=6a7393c6-8c08-49a8-b274-ae0cba71c9e4"
      alt="logo"/>
  )
}

const LogoNav = ({location}) =>
  location.pathname === "/userEdit"
    ? <Link to="/feed">
        <Logo />
      </Link>
    : location.pathname === "/info" || location.pathname === "/forgotPassword"
      ?
      <Link to="/">
        <Logo />
      </Link>
    : <Logo />


const InfoLink = ({location}) =>{
  const classes = useStyles()
  return (location.pathname !== "/" &&
    <Link to="/info" style={{textDecoration: 'none'}}>
      <Title classes={classes}>Info</Title>
    </Link>
  )
}

const ButtonAppBar = ()=> {
  const classes = useStyles();
  const {theme} = useContext(TemaContext)
  let location = useLocation()

  return (
    <div className={classes.root}>

      {theme.palette.type === 'light' 
      ? (<AppBar position="fixed" color="primary" elevation={1}>
          <Toolbar>
            <LogoNav location={location} />
            <Typography className={classes.titleItem}>
            </Typography>
            <InfoLink location={location}/>
            <LanguageSelector />
          </Toolbar>
        </AppBar>)
    : (<AppBar position="fixed" color="inherit">
        <Toolbar>
          <LogoNav location={location} />
          <Typography variant="h6" className={classes.titleItem}>
          </Typography>
          <InfoLink location={location}/>
          <LanguageSelector />
        </Toolbar>
      </AppBar>)
    }     
    </div>
  );
}

export default ButtonAppBar