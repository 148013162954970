import React, {useState, forwardRef} from 'react';
import {FormControl, Select, MenuItem, Typography, Box, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    formControl:{
        margin: theme.spacing(3),
        minWidth: 200
    },
    galleryImages:{
        display: 'flex',
        flexDirection: 'column',
    },
    galleryInput:{
        margin: theme.spacing(1,1)
    }
}))

interface ImageSelectorProps {
    t: (text: string) => React.ReactNode
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}


const ImageSelector: React.FC<ImageSelectorProps> = forwardRef(({t, handleInputChange}) => {
    const classes = useStyles()
    const [state, setState] = useState<string>("avatar")
    const handleSelectChange = (e :React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
        setState(e.target.value)
    return (
        <>
            <FormControl className={classes.formControl}>
                <Select variant="outlined" onChange={handleSelectChange} value={state}>
                    <MenuItem value="avatar">
                        <Typography variant="subtitle2" color="textSecondary">
                            Avatar
                        </Typography>
                    </MenuItem>
                    <MenuItem value="wallpaper">
                        <Typography variant="subtitle2" color="textSecondary">
                            {t("wallpaper")}
                        </Typography>
                    </MenuItem>
                    <MenuItem value="gallery">
                        <Typography variant="subtitle2" color="textSecondary">
                            {t("gallery")}
                        </Typography>
                    </MenuItem>
                </Select>
            </FormControl>
            {state === "avatar" && 
            <Box >
                    <Typography variant="subtitle2" color="textSecondary">Avatar</Typography>
                    <input
                        placeholder="avatar"
                        name="photoURL"
                        accept=".png, .jpg, .jpeg, .gif"
                        multiple={false}
                        onChange={handleInputChange}
                        type="file"
                        />

            </Box>
            }
            {state === "wallpaper" &&
                <Box >
                    <Typography variant="subtitle2" color="textSecondary">{t("wallpaper")}</Typography>
                    <Button>
                    <input
                        placeholder="wallpaper"
                        accept=".png, .jpg, .jpeg, .gif"
                        name="wallpaper"
                        multiple={false}
                        onChange={handleInputChange}
                        type="file"
                        />
                    </Button>
            </Box>
            }
            {state === "gallery" &&
                <div className={classes.galleryImages}>

                        <Typography variant="subtitle2" color="textSecondary">{t("image")} 1:</Typography>
                        <input
                            className={classes.galleryInput}
                            accept=".png, .jpg, .jpeg, .gif"
                            placeholder="image1"
                            name="image1"
                            multiple={false}
                            onChange={handleInputChange}
                            type="file"
                            />


                        <Typography variant="subtitle2" color="textSecondary">{t("image")} 2:</Typography>
                        <input
                            className={classes.galleryInput}
                            placeholder="image2"
                            accept=".png, .jpg, .jpeg, .gif"
                            name="image2"
                            multiple={false}
                            onChange={handleInputChange}
                            type="file"
                            />


                        <Typography variant="subtitle2" color="textSecondary">{t("image")} 3:</Typography>
                        <input
                            className={classes.galleryInput}
                            placeholder="image3"
                            accept=".png, .jpg, .jpeg, .gif"
                            name="image3"
                            multiple={false}
                            onChange={handleInputChange}
                            type="file"
                            />

                </div>
            }
          
        </>
    );
})

export default ImageSelector;
