import React, {Suspense, useContext} from 'react'
import {TemaContext} from './context/themeContext'
import Feed from './Views/Feed'
import Login from './Views/Login'
import EditProfile from './Views/EditProfile'
import UserProvider from './context/usersContext'
import OwnProfileProvider from './context/ownProfileContext'
import ReportedUSerProvider from './context/reportedUserContext'
import {ThemeProvider} from '@material-ui/core/styles'
import {CssBaseline} from '@material-ui/core'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './App.css'
import Suggestions from './Views/Suggestions'
import Info from './Views/Info.tsx'
import ForgotPassword from './Views/ForgotPassword'
import Report from './Views/Report'


export default function(){
  const {theme} = useContext(TemaContext)
  return(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={null}>
              <UserProvider>
                <OwnProfileProvider>
                  <ReportedUSerProvider>
                    <Router>
                      <Switch>
                        <Route exact path="/" component={Login} />
                        <Route path="/feed" component={Feed} />
                        <Route path="/suggestions" component={Suggestions} />
                        <Route path="/report" component={Report} />
                        <Route path="/info" component={Info} />
                        <Route path="/userEdit" component={EditProfile} />
                        <Route path="/forgotPassword" component={ForgotPassword} />
                      </Switch>
                    </Router>
                  </ReportedUSerProvider>
                </OwnProfileProvider>
              </UserProvider>
            </Suspense>
        </ThemeProvider>
  )
}