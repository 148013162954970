import React from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'



const useStyles = makeStyles(theme =>({
    root:{
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        height: theme.spacing(30),
        color: 'white',
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    image:{
        height: theme.spacing(20),
        width: theme.spacing(24)
    },
    textSubcolumn:{
        fontSize: '.8em',
        margin: theme.spacing(1,3,1,3)
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            <img src="https://firebasestorage.googleapis.com/v0/b/tradeo-ff7fd.appspot.com/o/recursos%2FilustracionPrincipal.png?alt=media&token=ff0e9f74-a8dd-471b-8356-5aeb1a1d116a"
                className={classes.image}
                alt="primary imagen" />
            <Typography className={classes.textSubcolumn}>
                <strong>
                    De todos para todos <br/>
                    Tulis {' '}
                </strong>
                    significa {' '}
                <strong>     
                    todos en maya, {' '}
                </strong>
                    porque todos podemos ayudarnos.<br/>
                    1ra plataform en el mundo
                    de intercambio en maya y español.
            </Typography>
        </Grid>
    );
}

export default Footer;
