import React from 'react'
import {DialogContent,DialogTitle, DialogContentText, DialogActions, Button} from '@material-ui/core'

const UsersGuide = ({onClose, t}) =>{
    return(
        <>
            <DialogTitle>
                Aviso a nuevos usuarios
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{fontSize: '0.9em'}}>
                    Para que los demas usuarios puedan saber que <br />
                    ofreces y que te interesa encontrar. Es importante <br />
                    que edites tu tipo de perfil <br /> <br/>

                    Para editarlo puedes navegar con el botón de usuario <br/>
                    en la barra inferior <span role="img" aria-label="arrow">↘️</span>
                    <br/>
                    <br/>
                    <strong>BONUS</strong>
                    <br/>
                    Agrega imagenes para que los demás usuarios te conozcan mejor y también <br/>
                    habilitá el contacto por whatsapp para que hagas trueques más rápidos!
                </DialogContentText>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t("accept")}</Button>
            </DialogActions>
        </DialogContent>
    </>
    )
}

export default UsersGuide