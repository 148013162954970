import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>({
    avatar:{
        width: theme.spacing(13),
        height: theme.spacing(13),
        border: '2px solid #cccc',
        margin: theme.spacing(1,0,1)
    },
}))

interface ProfileImageProps {
    img: string
}
const ProfileImage: React.FC<ProfileImageProps> = ({img}) =>{
    const classes = useStyles()
    return(  
        img ?
        <Avatar 
            src={img} 
            alt="profile" 
            className={classes.avatar}
        />
        :  <Avatar  
            alt="profile" 
            className={classes.avatar} />

    )
}
    


export default ProfileImage