import React, {useState} from 'react'
import {auth, createUserProfileDocument} from '../../firebase'
import { Grid,Paper} from '@material-ui/core'
import Terms from './terms'
import Fade from '../Fade'
import SignUpForm from './SignUpForm'
import DialogConditions from './DialogContidions'
import Copyright from './Copyright'
import LoginLinks from './LoginLinks'

import HeaderSignIn from '../HeaderSignIn'


const initialState = {displayName: "", email:"", password: "", confirmation: ""}

interface SignUpProps{
    t: (text: string) => string
    classes: any
    toggle: (event: React.MouseEvent<HTMLButtonElement>) => void
    loginAndSendGoogle: () => void
    history: any
    authRouter: any

}

const SignUp: React.FC<SignUpProps> = ({t,classes,toggle,loginAndSendGoogle,history,authRouter,}) =>{
    const [signUp, setSignUp] = useState(initialState)
    const [alert, setAlert] = useState<boolean>(false)
    const [terms, setTerms] = useState<boolean>(false)
    const [showSpinner, setSpinner] = useState<boolean>(false)
    const [openTerms, setOpenTerms] = useState<boolean>(false)
    const toggleTermsModal = () => setOpenTerms(!openTerms)
    const toggleSpinner = () => setSpinner(!showSpinner)
    const toggleTerms = () => setTerms(!terms)

    const {displayName, email, password, confirmation,} = signUp

    const handleInputs = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setSignUp({
            ...signUp,
            [e.target.name] : e.target.value
        })
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        if (password !== confirmation) return;
        try {
            toggleSpinner()
            const {user} = await auth.createUserWithEmailAndPassword(email, password)
            await createUserProfileDocument(user,{displayName})
            authRouter.login(history.push('/feed'))
            console.log('pushed')
        } catch (error) {
            if(error.code === "auth/email-already-in-use"){
                setAlert(true)
            }
        }
        setSignUp(initialState)
    }
return(
    <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <div className={classes.paper}>
            <HeaderSignIn>
                {t("signUp")} {t("and")} {t("share")}
            </HeaderSignIn>
             
            <SignUpForm 
                t={t} alert={alert} handleSubmit={handleSubmit} 
                handleInputs={handleInputs} toggleTerms={toggleTerms} 
                terms={terms} toggleTermsModal={toggleTermsModal}
                loginAndSendGoogle={loginAndSendGoogle}
                {...signUp} showSpinner={showSpinner}/>
            <LoginLinks t={t} toggle={toggle} login="signUp" />
            <Copyright />
            <DialogConditions open={openTerms}>
                <Fade in={openTerms}>
                    <div className={classes.paperTerms}>
                        <Terms onClose={toggleTermsModal} t={t}/>
                    </div>
                </Fade>
            </DialogConditions>
        </div>
    </Grid>
    )
}

export default SignUp