import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root:{
        margin: theme.spacing(2,0,0,0),
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    avatar:{
        width: theme.spacing(17),
        height: theme.spacing(17)
    },
    main:{
        display: 'flex',
        flexDirection: 'column'
    },
    description:{
        marginLeft: theme.spacing(1),
        fontSize: '.9em'
    },
    title:{
        fontSize: '1.5em'
    }
}))

export interface Creator {
    name: string
    id: number
    image: string
    description: string,
}

interface ProfileDescriptionProps {
    children?: React.ReactNode
    creator: Creator
}
const ProfileDescription: React.FC<ProfileDescriptionProps> = ({creator}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <Avatar className={classes.avatar} src={creator.image} alt="creator-profile" />
                <Typography className={classes.title}>{creator.name}</Typography>
            </div>
            <div className={classes.description}>
                <p>{creator.description}</p>
            </div>
        </div>
    );
}

export default ProfileDescription;
