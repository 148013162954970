import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>({
    wrapper:{
        display: 'flex',
        justifyContent: 'center'
    },
    root:{
        margin: theme.spacing(2,0,2)
    }
}))

const LoadUsers = ({onClick}) => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <IconButton onClick={onClick} size="lg" className={classes.root}>
                <AddIcon color="secondary"/>
            </IconButton>
        </div>
    );
}

export default LoadUsers;
