import React, {useState, createContext, useCallback} from 'react'
import {createMuiTheme} from '@material-ui/core'
import cyan from '@material-ui/core/colors/cyan'

const themeObject = {
    palette:{
      primary: {main: cyan[500]},
      secondary: {main: '#00d3d6'},
      info:{main: '#006064'},
      type:'light'
    },
    typography:{
        fontFamily: 'Roboto'
    }
  }

export const TemaContext = createContext()

const TemaProvider = ({children}) =>{
    const [theme, setTheme] = useState(createMuiTheme(themeObject))

    const toggleTheme = useCallback(() =>{
        const newTheme = createMuiTheme({
        ...theme,
        palette: {
            type: theme.palette.type === 'light' ? 'dark' : 'light',
            primary: {main: cyan[500]},
            secondary: {main: '#00d3d6'},
            info:{main: '#006064'},}
        })
        setTheme(newTheme)
    }, [theme])
    
    return(
        <TemaContext.Provider value={{theme, toggleTheme}}>
            {children}
        </TemaContext.Provider>
    )
}

export default TemaProvider