import React from 'react';
import TextField from '@material-ui/core/TextField'
import LoginButtons from './LoginButton';
import AlertMessage from './AlertMessage'
import {SpinnerLogin} from '../Spinner'

interface SignInFormProps{
    t: (text: string) => string
    classes: any
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
    email: string
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    password: string
    loginAndSendGoogle: () => void
    showSpinner: boolean
    wrongCredentials: boolean
    tooManyReqs: boolean
}


const SignInForm: React.FC<SignInFormProps> = ({t,classes, handleSubmit, email, handleInputChange, password,
                    loginAndSendGoogle, showSpinner, wrongCredentials, tooManyReqs}) =>
    <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id='email'
            label={t('email')}
            name="email"
            autoComplete={t("email")}
            autoFocus
            value={email}
            onChange={handleInputChange}
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            name="password"
            label={t('password')}
            autoComplete={t('password')}
            value={password}
            onChange={handleInputChange}
        />
        <SpinnerLogin show={showSpinner} />
        <AlertMessage alert={wrongCredentials} severity="error">
            {t('notFound')}
        </AlertMessage>
        <AlertMessage alert={tooManyReqs} severity="error">
            {t("tooManyReqs")}
        </AlertMessage>
        <LoginButtons t={t} clickGoogle={loginAndSendGoogle} login="signIn"/>
    </form>
     

export default SignInForm;
