import {shuffle} from '../utils/shuffle'

export const feedReducers = (state, action) =>{
    switch(action.type){
      case 'SET_LIST_INIT':
        return {
          ...state,
          isLoading: true,
          isError: false
        }
      case 'SET_LIST_SUCCESS':
        return{
          ...state,
          isLoading: false,
          isError: false,
          data: action.payload.users,
          displayList: action.payload.users,
          lastUser: action.payload.lastUser
        }
      case 'SET_LIST_FAILURE':
        return{
          ...state,
          isLoading: false,
          isError: true
        }
      case 'SET_REMOVE_USER':
        return{
          ...state,
          displayList: state.displayList.filter(user =>
            action.payload.uid !== user.uid
          )
        }
      case 'SET_DRAWER_USER':
        return{
          ...state,
          user: action.payload
        }
      case 'SET_FILTER':
        return{
          ...state,
          displayList: shuffle(state.data.filter(user =>
            action.payload === user.type
          ))
        }

      case 'SET_USERS_FILTER':
        return{
          ...state,
          displayList: shuffle(action.payload)
        }
      case 'SET_FILTER_ALL':
        return{
          ...state,
          displayList: shuffle(state.data.concat())
        }
      case 'SET_FILTER_TAGS':
        return{
          ...state,
          displayList: shuffle(state.data.filter(user =>
            user.tag.includes(action.payload)
          ))
        }
      case 'SET_LOCATION_FILTER':
        return{
          ...state,
          displayList: shuffle(state.data.filter(user =>
            user.location.city === action.payload))
        }
      case 'SET_LOGIN_GOOGLE':
        return{
          ...state,
          userProfile: action.payload
        }
      case 'ADD_MORE_USERS':{
        const newUsers = action.payload
        const concated = state.data.concat(newUsers)
        return{
          ...state,
          data: concated,
          displayList: concated,
          lastUser: newUsers[newUsers.length -1]
        }
      }
      default: new Error()
      }
    }
  