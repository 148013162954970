import React, { forwardRef,} from 'react'
import {auth} from '../../firebase'
import authRouter from '../../auth'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ToggleTheme from '../ToggleTheme'
import ProfileImage from './ProfileImage'
import WhatsappIcon from './WhatsappIcon'
import Button from '@material-ui/core/Button'
import {ButtonStyled} from '../Login/LoginButton'


const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        width: theme.spacing(35),
        padding: theme.spacing(2),
        height: '100vh',
        border: '2px solid #cccc',
        flexDirection: 'column',
        justifyContent: 'start',
        textAlign: 'start',
        alignItems: 'center',
        alignContent: 'center',
        
    },
    textName:{
        color: theme.palette.primary.main,
        fontSize: '2em',
        fontWeight: 800,

    },
    textSubtitle:{
        color: '#9e9e9e',
        fontSize: '1em',
        fontWeight: 350
    },
    contact:{
        display: 'flex',
    
        margin: theme.spacing(1,0,1,3),
        width: theme.spacing(20)
    },
    button:{
        margin: theme.spacing(1)
    }
 
}))

const ButtonProfile = ({onClick, children, variant}) =>{
    const classes = useStyles()
    return(
        <Button className={classes.button} onClick={onClick} 
            variant={variant} size="small" color="secondary">{children}</Button>
    )
}

const UserOwnProfile = forwardRef(({history, user, t}, ref) => {
    const {displayName, number, photoURL, email} = user
    const classes = useStyles()

    function go(route){
        if (route === "edit"){
            history.push('/userEdit')
        } else if (route === "signOut"){
            authRouter.logout(history.push('/'))
            auth.signOut()
        } else if  (route ==="suggestions"){
            history.push('/suggestions')
        }
    }

    return (
        <Grid container className={classes.root} ref={ref}>
            <ProfileImage img={photoURL} />
            <Typography className={classes.textName}><strong>{displayName}</strong></Typography>
            <Typography className={classes.textSubtitle}>{email}</Typography>
            {number && <WhatsappIcon number={number} showNumber={true} />}
            <ToggleTheme />
            <ButtonStyled variant="contained"onClick={() => go("edit")}>{t("editProfile")}</ButtonStyled>
            <ButtonProfile variant="outlined" onClick={() => go("suggestions")}>sugerencias</ButtonProfile>
            <ButtonProfile variant="outlined" onClick={() => go("signOut")}>{t("signOut")}</ButtonProfile>
        </Grid>
    );
})

export default UserOwnProfile;
