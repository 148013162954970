import React, {useState, createContext} from 'react';

export const ReportedUserContext = createContext()

const ReportedUserProvider = ({children}) => {
    const [reportedUser, setReported] = useState(null)
    function reportUser(user){
        const {displayName, uid} = user
        setReported({displayName, uid})
    }
    return (
        <ReportedUserContext.Provider value={{reportedUser, reportUser}}>
            {children}
        </ReportedUserContext.Provider>
    );
}

export default ReportedUserProvider;
