import React from 'react'
import {DialogContent,DialogTitle, DialogContentText, DialogActions, Button} from '@material-ui/core'

const UsersGuide = ({onClose, t}) =>{
    return(
        <>
            <DialogTitle>
                Guía de Usuarios / Users Guide 
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{fontSize: '0.9em'}}>
                    #|
                    Respeta a los demás
                    <br/><br/>
                    -El respeto al prójimo en tulis es muy importante, trata a todos como te gustaría que te trataran. 
                    -NO insultes, ofendas, discrimines, amenaces, acoses, humilles… nadie se merece ser tratado así. 
                    -tulis es una comunidad que se basa en la colaboración entre personas, como nos podemos ayudar entre nosotros para resolver nuestros problemas, por lo que la convivencia es fundamental.
                    Así que mantengamos un ambiente armonioso y colaborativo en donde todos respetemos y seamos respetados. 
                    <br/><br/>

                    Si llegas a tener algún problema con otra persona, no dudes en contactarnos al correo: info.tulis.app@gmail.com

                    <br/><br/>

                    #2 Nada ilegal 
                    <br/><br/>

                    En tulis somos muy estrictos con los comportamientos ilegales, están absolutamente prohibidos. 
                    NO se puede intercambiar, vender, ofrecer, buscar y/o preguntar por sustancias, servicios y/o objetos ilegales, obscenos, peligrosos, tóxicos, violentos, sexuales, psicotrópicas (drogas) o que infrinjan las leyes locales, estatales, nacionales e internacionales.
                    Quién traté de intercambiar, vender, ofrecer, buscar y/o preguntar por algo de lo mencionado arriba, estará expulsado de inmediato. No hay excepciones. 
                    Lo ilegal NO es cool 
                    <br/><br/>

                    #3 Se responsable de tus acciones
                    <br/><br/>

                    NO somos responsables de las acciones de los usuarios

                    Todos los usuarios de tulis son mayores de edad, adultos, así que nosotros tulis no somos responsables de tus acciones ni las de los demás usuarios. 

                    Lo que digan, hagan, escriban y/o insinúen es responsabilidad de cada quien, así que cuiden sus acciones y recuerden que  siempre respeten a los demás. 
                    <br/><br/>

                    #4
                    Solo la verdad 
                    <br/><br/>

                    Todo la información que subas en tulis debe ser real, la información sobre ti y sobre lo que ofreces.
                    Ser sinceros es muy importante, así que di solo la verdad, tulis NO es un espacio para inventarse cuentos NI decir mentiras.
                    <br/><br/>

                    #5 
                    Cumple con los términos y condiciones 
                    <br/><br/>

                    Los términos y condiciones son las reglas de juego, así que por el bien de toda la comunidad síguelas al pie de la letra. 
                    Si todos respetamos las reglas vamos a lograr una convivencia perfecta. 

                    ¡Gracias por respetarlas!

                    Si no las has leído aún, puedes leer aquí [aquí es link a las reglas]
                    <br/><br/>

                    #1
                    Respect others
                    <br/><br/>
                    
                    -Respect the others in tulis is very important, treat everyone as you would like to be treated.
                    -Do NOT insult, offend, discriminate, threaten, harass, humiliate ... nobody deserves to be treated this way.
                    -tulis is a community that is based on collaboration between people, as we can help each other to solve our problems, so coexistence is essential.
                    So, let's maintain a harmonious and collaborative environment where we all respect and are respected.
                    <br/><br/>

                    If you have any problem with another person, do not hesitate to contact us at: info.tulis.app@gmail.com
                    <br/><br/>

                    #2

                    Nothing illegal
                    <br/><br/>

                    At tulis we are very strict about illegal behavior, they are absolutely prohibited.

                    It is NOT allowed to exchange, sell, offer, search and / or ask for illegal, obscene, dangerous, toxic, violent, sexual, psychotropic (drugs) substances, services and / or objects that violate local, state, national and international laws. .

                    Whoever tried to exchange, sell, offer, search and / or ask about any of the above, will be immediately expelled. There are NO exceptions.

                    Illegal is NOT cool
                    <br/><br/>

                    #3

                    Be responsible for your actions
                    <br/><br/>

                    We are NOT responsible for the actions of users

                    All tulis users are of legal age, adults, so we tulis are NOT responsible for your actions or those of other users.

                    What each one say, do, write and / or insinuate is this person responsibility, so take care of your actions and remember to always respect others.
                    <br/><br/>

                    #4
                    Only the truth
                    <br/><br/>

                    All the information you upload in tulis must be real, the information about you and what you offer.
                    Being honest is very important, so just tell the truth, tulis is NOT a place to make up stories or tell lies.

                    <br/><br/>

                    #5
                    Follow with the terms and conditions
                    <br/><br/>

                    The terms and conditions are the rules of the game, so for the good of the entire community, follow them to the letter.
                    If we all respect the rules we will achieve a perfect coexistence in community.

                    Thanks for respecting them!

                    If you haven't read them yet, you can read here [here is a link to the rules]

                </DialogContentText>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t("accept")}</Button>
            </DialogActions>
        </DialogContent>
    </>
    )
}

export default UsersGuide