import React, {useState, createRef} from 'react';
import ImageSelector from './ImageSelector'
import {firestore, storage} from '../../firebase'
import Typography from '@material-ui/core/Typography'
import AlertMessage from '../Login/AlertMessage'
import {ButtonStyled} from '../Login/LoginButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'


const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        height: theme.spacing(40),
        maxWidth: theme.spacing(50),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(8,2,10,4),
        padding: theme.spacing(5),
        [theme.breakpoints.up("md")]:{
            margin: theme.spacing(8,2,3,4),
        }
    },
    textTitle:{
        color: theme.palette.primary.main,
        fontSize: '1.8em',
        fontWeight: 800
    }
}))

const initialImages = {photoURL: "",image1: "",image2: "",image3: "",wallpaper: ""}

const EditImages = ({t, showAlert, toggleAlert, user}) => {
    const [images, setImages] = useState(initialImages)
    const [limitImage, setLimitImage] = useState(false)
    const classes = useStyles()
    const imageSelectorRef = createRef()

    const handleInputChange = e =>{
        if(e.target.files[0].size > 2000000){
            setLimitImage(true)
            return
        }
        setLimitImage(false)
        setImages({...images, [e.target.name] : e.target.files[0]})
    }

    const handleSubmit = async e =>{
        e.preventDefault()

        const userRef = firestore.doc(`users/${user.uid}`)
        const {image1, image2, image3, photoURL, wallpaper} = images

        if(photoURL !== ''){
            storage.ref(`profileAvatars/${user.uid}`)
            .put(photoURL)
            .then(response => response.ref.getDownloadURL())
            .then(photoURL => userRef.update({photoURL}))
            .catch(error => console.error('Error updating avatar image', error))
        }
        if(image1 !== ''){
            storage.ref(`galleryImage1/${user.uid}`)
            .put(image1)
            .then(response => response.ref.getDownloadURL())
            .then(image1 => userRef.update({image1}))
            .catch(error => console.error('Error updating image1', error))
        }
        if(image2 !== ''){
            storage.ref(`galleryImage2/${user.uid}`)
            .put(image2)
            .then(response => response.ref.getDownloadURL())
            .then(image2 => userRef.update({image2}))
            .catch(error => console.error('Error updating image2', error))
        }
        if(image3 !== ''){
            storage.ref(`galleryImage3/${user.uid}`)
            .put(image3)
            .then(response => response.ref.getDownloadURL())
            .then(image3 => userRef.update({image3}))
            .catch(error => console.error('Error updating image3', error))
        }
        if(wallpaper !== ''){
            storage.ref(`wallpapers/${user.uid}`)
            .put(wallpaper)
            .then(response => response.ref.getDownloadURL())
            .then(wallpaper => userRef.update({wallpaper}))
            .catch(error => console.error('Error updating image3', error))
        }
        toggleAlert()

    }
    return (
        <Grid item component={Paper} onSubmit={handleSubmit} elevation={3} variant="outlined" className={classes.root}>
            <Typography className={classes.textTitle}>
                <strong>{t("profileImages")}  <span role="img" aria-label="picture">🖼️</span></strong>
            </Typography>
            <ImageSelector t={t} ref={imageSelectorRef} handleInputChange={handleInputChange} />
            <AlertMessage severity="success" alert={showAlert}>Images submitted!</AlertMessage>
            <AlertMessage severity="error" alert={limitImage}>Image must be lower than 2 mb</AlertMessage>
            <ButtonStyled
                onClick={handleSubmit}
                disabled={limitImage}
                variant="contained"
            >
                {t("submit")}
            </ButtonStyled>
        </Grid>
    );
}

export default EditImages;
