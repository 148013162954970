import React, {useState} from 'react';
import {firestore} from '../../firebase'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import SelectContact from './SelectContact'
import SelectTypes from './SelectTypes'
import SelectInterest from './SelectInterest'
import Description from './Description'
import AlertMessage from '../Login/AlertMessage'
import {makeStyles} from '@material-ui/core/styles'
import {ButtonStyled} from '../Login/LoginButton'

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        maxWidth: theme.spacing(50),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(8,2,2,4),
        padding: theme.spacing(5),
        [theme.breakpoints.up("sm")]:{
            margin: theme.spacing(8,2,13,4),

        }
    },
    textTitle:{
        color: theme.palette.primary.main,
        fontSize: '1.8em',
        fontWeight: 800
    }
}))


const initialState={displayName: "", tag:"",number: "",type: "",subType: "", city: "", 
                    contact:"", skill:"", interest:{type:"", area:""}, description:""}



const EditInfo = ({ t, toggleAlert, showAlert, user}) => {
    const [edit, setEdit] = useState(initialState)
    const classes = useStyles()
    const handleInputChange = e =>
        setEdit({...edit, [e.target.name] : e.target.value})

    const handleSubmit = async e =>{
        e.preventDefault()

        const userRef = firestore.doc(`users/${user.uid}`)

        const { displayName, tag, number, type, subType, skill, city, contact, interest, description} = edit

        if (displayName){
            userRef.update({displayName})
        }
        if(tag){
            userRef.update({tag})
        }
        if(type){
            userRef.update({type})
        }
        if(subType){
            userRef.update({subType, skill: ""})
        }
        if(number){
            if (number[0] !== "+"){
                userRef.update({number:`+52${number}`})
            } else {
                userRef.update({number})
            }
        }
        if(skill){
            userRef.update({skill})
        }
        if(city){
            const location = {country: "mexico", state: "qroo", city}
            userRef.update({location})
        }
        if(contact){
            userRef.update({contact})
        }
        if(interest.area !== ""){
            userRef.update({interest})
        }
        if(description){
            userRef.update({description})
        }
        setEdit({...edit, displayName: "", tag: "", type: "", subType: "", number: "", 
                skill: "", interest:{type:"", area:""}, description: ""})
        toggleAlert()
    }

    return (
        user ? 
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={3} variant="outlined" className={classes.root}>
                <Typography  className={classes.textTitle}>
                    <strong>{t("personalInfo")}
                    <span role="img" aria-label="memo">📝</span>
                    </strong>
                </Typography>
                <TextField
                    name="displayName"
                    value={edit.displayName}
                    label={t("name")}
                    onChange={handleInputChange}
                />
                <TextField
                    name="number"
                    value={edit.number}
                    label="Whatsapp"
                    onChange={handleInputChange}
                />
                <SelectTypes 
                    classes={classes}
                    t={t} setEdit={setEdit} edit={edit} />
                <TextField
                    name="tag"
                    value={edit.tag}
                    label={t("tag")}
                    onChange={handleInputChange}
                />
                <SelectInterest t={t} edit={edit} setEdit={setEdit}/>
                <SelectContact t={t} contact={edit.contact} contactChange={handleInputChange}/>
                <Description t={t} description={edit.description} onChange={handleInputChange} size="8"/>
                <AlertMessage severity="success" alert={showAlert}>Info submitted!</AlertMessage>
                <ButtonStyled onClick={handleSubmit} variant="contained">
                    {t("submit")}
                </ButtonStyled>

            </Grid>
        
        : <p>Loading...</p>
  
    );
}

export default EditInfo;
