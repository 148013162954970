import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {auth} from '../firebase'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import MainLayout from '../layouts/MainLayout'
import AlertMessage from '../components/Login/AlertMessage'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
    root:{
        display: 'flex',
        justifyContent: 'center'
    },
    form:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        margin: theme.spacing(10,0,0,0),
        border: '1px solid black',
        borderRadius: '25px',
        maxWidth: 360,
        textAlign: 'center'
    },
    title:{
        color: theme.palette.primary.dark,
        fontSize: '2em',
        fontWeight: 800
    },
    textfield: {
        margin: theme.spacing(2)
    },
    button:{
        margin: theme.spacing(1,5,2,5)
    }
}))

interface FormProps{
    textfield: string
    inputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    title: string
    showAlert: boolean
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Form: React.FC<FormProps> = ({textfield, inputChange, title, showAlert, onSubmit}) => {
    const classes = useStyles()
    return(
        <Grid container className={classes.form}>
            <FormControl >
                <Typography className={classes.title}>{title}</Typography>
                <TextField label={textfield} onChange={inputChange} className={classes.textfield}/>
                <AlertMessage alert={showAlert} severity="success">
                    Correo enviado!
                </AlertMessage>
                <Button onClick={onSubmit} variant="contained" color="secondary" className={classes.button}>Recuperar</Button>
            </FormControl>
        </Grid>
    )
}

const ForgotPassword = () => {
    const classes = useStyles()
    const [textfield, setTextfield] = useState("email")
    const [showAlert, setAlert] = useState(false)
    const toggleAlert = () =>{
        setAlert(true)
        setTimeout(() =>{
            setAlert(false)
        }, 2500)
    }
    const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => setTextfield(event.target.value)
    const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) =>{
        event.preventDefault()
        try {
            await auth.sendPasswordResetEmail(textfield)
            toggleAlert()
        } catch (error) {console.error('Error with email', error)}
    }

    return (
        <MainLayout>
            <div className={classes.root}>
                <Form title="Recuperar contraseña" textfield={textfield} 
                    inputChange={inputChange} showAlert={showAlert} onSubmit={onSubmit}/>
            </div>
        </MainLayout>
    );
}

export default ForgotPassword;
