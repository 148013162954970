import React, {forwardRef} from 'react'
import {animated, useSpring} from 'react-spring'


interface FadeForwardProps {
    in?: boolean
    onEnter?: () => void
    onExited?: () => void
    children?: React.ReactNode
}


const Fade = forwardRef((props: FadeForwardProps, ref: any) =>{
    const {in: open, children, onEnter, onExited, ...other} = props;
    const style = useSpring({
        from: {opacity: 0},
        to: {opacity: open ? 1: 0},
        onStart: () =>{
            if (open && onEnter){
                onEnter()
            }
        },
        onRest: () =>{
            if (!open  && onExited){
                onExited()
            }
        }
    })

    return(
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    )
})

export default Fade